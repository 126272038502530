import { UserManager as OidcUserManager, User, UserManagerSettings } from "oidc-client-ts";
import { AuthService } from "./AuthService";
import { WindowRouter } from "../shell/WindowRouter";

export class UserManager {
    private _manager: OidcUserManager | null = null;
    private _organizationServiceBaseUrl = "";
    private _identityServerBaseUrl = "";
    private _postLogoutRedirectUri = "";
    private _pendoApiKey = "";
    private _authority = "";

    get manager(): OidcUserManager | null {
        return this._manager;
    }

    constructor(
        private readonly authService: AuthService,
        private readonly windowRouter: WindowRouter
    ) {}

    isInitialized = (): boolean => {
        return this._manager != null;
    };

    initAsync = async (): Promise<void> => {
        if (!this._manager) {
            const data = await this.authService.getConfig();

            const config: UserManagerSettings = {
                authority: `${data.authority}`,
                client_id: `${data.client}`,
                redirect_uri: `${data.postLoginRedirectUri}`,
                response_type: `${data.responseType}`,
                scope: `${data.scope}`,
                post_logout_redirect_uri: `${data.postLogoutRedirectUri}`,
                automaticSilentRenew: true,
                filterProtocolClaims: true,
                loadUserInfo: true,
                response_mode: "query"
            };

            this._organizationServiceBaseUrl = data.organizationServiceUrl;
            this._identityServerBaseUrl = data.identityServerBaseUrl;
            this._postLogoutRedirectUri = data.postLogoutRedirectUri;
            this._authority = data.authority;

            this._manager = new OidcUserManager(config);

            this._manager.events.addUserSignedOut(() => {
                if (this._manager) {
                    this._manager.removeUser();
                }
            });

            this._pendoApiKey = data.pendoApiKey;
        }
    };

    signInRedirectCallbackAsync = async (): Promise<void> => {
        await this._manager?.signinRedirectCallback();

        let redirect = "/404";

        if (await this.isAuthenticatedAsync()) {
            redirect = window.sessionStorage.getItem("redirect_uri") || "/";
        }
        this.windowRouter.assign(redirect);
    };

    signIn = async (from: string): Promise<void> => {
        window.sessionStorage.setItem("redirect_uri", from);
        await this._manager?.signinRedirect();
    };

    signOut = async (): Promise<void> => {
        await this._manager?.signoutRedirect();
    };

    getUserAsync = async (): Promise<User | undefined> => {
        const user = await this._manager?.getUser();
        return user || undefined;
    };

    isAuthenticatedAsync = async (orgId?: string | null): Promise<boolean> => {
        const user = await this.getUserAsync();

        const authenticated = user !== undefined && !user.expired;

        return authenticated;
    };

    isSuperUser = async (): Promise<boolean> => {
        const user = await this.getUserAsync();
        return ((user?.profile?.role as string[]) ?? [])?.includes("notifications_application_superuser");
    };

    getOrgServiceBaseUrl = (): string => {
        return this._organizationServiceBaseUrl;
    };

    getIdentityServerBaseUrl = (): string => this._identityServerBaseUrl;

    getPostLogoutRedirectUrl = (): string => this._postLogoutRedirectUri;

    getAuthority = (): string => this._authority;

    getSignoutUrl = async (orgId: string): Promise<string> => {
        const user = await this.getUserAsync();
        return `${this.getAuthority()}/connect/endsession?post_logout_redirect_uri=${this.getPostLogoutRedirectUrl()}&id_token_hint=${
            user?.id_token
        }&state=${orgId}`;
    };

    getResetPasswordUrl = (): string => {
        return `${this.getIdentityServerBaseUrl()}/Identity/Account/Manage/ChangePassword`;
    };

    getSettingsUrl = (): string => {
        return `${this.getIdentityServerBaseUrl()}/Identity/Account/Manage`;
    };

    getPendoKey = (): string => {
        return this._pendoApiKey;
    };
}
