import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { bottle } from "../../provider/Bottle";
import { UserManager } from "../../services/UserManager";
import { OrganizationService } from "../../services/OrganizationService";

interface VisitorDetails {
    id?: string;
    role: string;
    notifications_is_admin: boolean;
    notifications_is_superuser: boolean;
    notifications_is_staff: boolean;
    notifications_is_public: boolean;
}

interface PendoAccountDetails {
    id?: string;
    name?: string;
    org_friendly_name?: string;
    is_notifications: boolean;
}

declare global {
    interface Window {
        pendo?: {
            initialize?: (options: {
                visitor: VisitorDetails;
                account: PendoAccountDetails;
                disablePersistence: boolean;
            }) => void;
            updateOptions?: (options: {
                visitor: VisitorDetails;
                account: PendoAccountDetails;
                disablePersistence: boolean;
            }) => void;
            _q?: unknown[];
        };
    }
}

const ignoredOrgsPendo = ["ui-automation", "kraken", "cp-internal"];

function pendoSetupAndInitialization(
    apiKey: string,
    isInitialized: boolean,
    visitorData: VisitorDetails,
    accountData: PendoAccountDetails
) {
    (function () {
        (function (p: Window, e: Document, n: "script", d: "pendo"): void {
            let w: number, x: number;
            const o = (p[d] = p[d] || {});
            o._q = o._q || [];
            const v: string[] = ["initialize", "identify", "updateOptions", "pageLoad", "track"];
            for (w = 0, x = v.length; w < x; ++w)
                (function (m: string) {
                    //@ts-ignore
                    o[m] =
                        //@ts-ignore
                        o[m] ||
                        function (...args: unknown[]) {
                            //@ts-ignore
                            o._q[m === v[0] ? "unshift" : "push"]([m].concat([].slice.call(args, 0)));
                        };
                })(v[w]);
            const y = e.createElement(n);
            y.async = !0;
            y.src = "https://cdn.pendo.io/agent/static/" + apiKey + "/pendo.js";
            const z = e.getElementsByTagName(n)[0];
            z.parentNode?.insertBefore(y, z);
        })(window, document, "script", "pendo");
        const setupFunction = isInitialized ? window?.pendo?.initialize : window?.pendo?.updateOptions;
        if (setupFunction) {
            setupFunction({
                visitor: visitorData,
                account: accountData,
                disablePersistence: true
            });
        }
    })();
}

const Pendo: React.FC = () => {
    const [initializedPendo, setInitializedPendo] = useState(false);
    const [pendoOrgName, setPendoOrgName] = useState<string>();
    const userManager: UserManager = bottle.container.UserManager;
    const organizationService = bottle.container.OrganizationService as OrganizationService;

    const pendoKey = userManager.getPendoKey();

    useEffect(() => {
        const sendUserInfoToPendo = async () => {
            try {
                const user = await userManager.getUserAsync();
                const orgDto = await organizationService.getOrganization();
                if (user === undefined) {
                    setInitializedPendo(true);
                    return;
                }

                if (ignoredOrgsPendo.includes((orgDto?.organization?.name ?? "").toLowerCase())) {
                    setInitializedPendo(true);
                }

                let userRole = "Public Users";
                let notifications_is_admin = false;
                let notifications_is_public = false;
                const isCivicPlusStaff = user?.profile?.name?.includes("@civicplus.com") ?? false;

                if (isCivicPlusStaff) {
                    userRole = "CivicPlus Staff";
                } else if (orgDto?.hasAccess || orgDto.isOrgOwner) {
                    userRole = "Customer Admin";
                    notifications_is_admin = true;
                } else {
                    userRole = "Public User";
                    notifications_is_public = true;
                }

                const visitorData = {
                    id: user?.profile?.sub ?? "",
                    role: userRole,
                    notifications_is_admin,
                    notifications_is_superuser: await userManager.isSuperUser(),
                    notifications_is_staff: isCivicPlusStaff,
                    notifications_is_public
                };

                const accountData = {
                    id: orgDto?.organization?.id ?? "",
                    name: orgDto?.organization?.name,
                    org_friendly_name: orgDto?.organization?.friendlyName ?? "",
                    is_notifications: true
                };

                pendoSetupAndInitialization(
                    pendoKey,
                    !initializedPendo || pendoOrgName !== orgDto?.organization?.name,
                    visitorData,
                    accountData
                );

                setPendoOrgName(orgDto?.organization?.name);
            } catch (e) {
                console.error("Pendo failed to initialize", e);
            }
        };

        if (pendoKey) {
            sendUserInfoToPendo();
        }
    }, [initializedPendo, organizationService, pendoKey, pendoOrgName, userManager]);

    return <></>;
};

export default Pendo;
