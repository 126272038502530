import React, { useCallback, useEffect, useState, useMemo } from "react";
import DashboardIcon from "@material-ui/icons/Dashboard";
import HistoryIcon from "@material-ui/icons/History";
import Loader from "@civicplus/preamble-ui/lib/Loader";
import ManageAccountsIcon from "mdi-material-ui/AccountCog";
import NoteIcon from "@material-ui/icons/Note";
import Page from "@civicplus/preamble-ui/lib/Page";
import PeopleIcon from "@material-ui/icons/People";
import SettingsIcon from "@material-ui/icons/Settings";
import SvgIcon from "@material-ui/core/SvgIcon";
import ViewListIcon from "@material-ui/icons/ViewList";
import Zendesk from "@civicplus/preamble-ui/lib/ThirdParty/ZenDesk/Zendesk";
import { bottle } from "../../provider/Bottle";
import { CppOrganization } from "@civicplus/preamble-ui/lib/Services/OrgService/Types/Organization";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { mdiAccountKey, mdiCalendarClock, mdiEmailArrowRightOutline } from "@mdi/js";
import { Organization } from "../../entities/Organization";
import { OrganizationSettings } from "../../entities/OrganizationSettings";
import { ReactComponent as MySubscriptionLogo } from "../../app_registration-24px.svg";
import { UserManager } from "../../services/UserManager";
import { getCppOrganizationFromOrganization } from "../admin/shared/functions";
import { User } from "oidc-client-ts";
import NotificationsOrganizationService from "../../provider/notificationsOrganizationService";
import Pendo from "../../components/Pendo";

interface MenuItem {
    display: string;
    action: () => Window | void | null;
}

function withRouter(Component: any) {
    function ComponentWithRouterProp(props: any) {
        const location = useLocation();
        const navigate = useNavigate();
        const params = useParams();
        return <Component {...props} router={{ location, navigate, params }} />;
    }

    return ComponentWithRouterProp;
}

interface PageWrapperProps {
    isCitizenView: boolean;
    currentOrgSettings: OrganizationSettings;
    width?: any;
    children: any;
}

const PageWrapperInternal: React.FC<PageWrapperProps> = ({ isCitizenView, currentOrgSettings, width, children }) => {
    const orgService = bottle.container.OrgService;
    const apiService = bottle.container.ApiService;
    const userManager = bottle.container.UserManager as UserManager;

    const [organization, setOrganization] = useState<Organization | null>();
    const [orgId, setOrgId] = useState<string>("");
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [signoutUrl, setSignoutUrl] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(true);
    const [isSuperUser, setIsSuperUser] = useState<boolean>(false);
    const [authUser, setAuthUser] = useState<User>();
    const location = useLocation();

    useEffect(() => {
        const getUser = async () => {
            const user = await userManager.getUserAsync();
            setAuthUser(user);
        };

        getUser();
    }, [userManager]);

    const setupZenDesk = useCallback(async () => {
        if (!isCitizenView) {
            await Zendesk.initialize({
                closeButtonColor: undefined,
                departmentSelected: "CP Notify",
                departments: ["CP Notify"],
                key: "c9a79f7a-2e63-46e3-b5e0-82f7cba19902"
            });

            if (authUser && !authUser.expired) {
                Zendesk.identify({
                    firstName: authUser.profile.given_name,
                    lastName: authUser.profile.family_name,
                    email: authUser.profile.email
                });
            }
            const key = "show_zendesk_onload";
            Zendesk.onHide(() => {
                localStorage.removeItem(key);
            });
            Zendesk.onShow(() => {
                localStorage.setItem(key, "true");
            });
            if (localStorage.getItem(key)) {
                Zendesk.show();
            }
        }
    }, [authUser, isCitizenView]);

    useEffect(() => {
        const lastOrg = localStorage.getItem("organization:Notifications");
        const lastOrgFormatted = lastOrg && lastOrg!.replace(/['"]+/g, "");

        async function init() {
            if (orgService) {
                const orgId = lastOrgFormatted ? lastOrgFormatted : orgService.orgId;
                const signoutUrl = await userManager.getSignoutUrl(orgId);

                setOrganization(orgService.organization);
                setOrgId(lastOrgFormatted ? lastOrgFormatted : orgId);
                setIsAuthenticated(orgService.user !== null);
                setSignoutUrl(signoutUrl);
            }

            const isSuperUser = await userManager.isSuperUser();

            setIsSuperUser(isSuperUser);
            setLoading(false);
            await setupZenDesk();
        }
        init();
    }, [orgService, setupZenDesk, userManager]);

    const pageOrganization: CppOrganization | undefined = useMemo(() => {
        if (organization != null) {
            return getCppOrganizationFromOrganization(organization);
        }

        return undefined;
    }, [organization]);

    const orgServiceSdk = useMemo(
        () => new NotificationsOrganizationService(apiService, authUser),
        [authUser, apiService]
    );

    const buildNavigation = () => {
        const nav = {} as {
            [key: string]: {
                isSelected: any;
                title?: string;
                hide?: boolean;
                items: { [key: string]: Record<string, unknown> };
            };
        };

        nav.Dashboard = {
            items: {
                Dashboard: {
                    title: "Dashboard",
                    component: (props: any) => <Link {...props} to={`/${orgId}/admin`} />,
                    href: `/${orgId}/admin`,
                    icon: <DashboardIcon />,
                    hide: !isSuperUser && !organization?.isOrgOwner && !organization?.hasAccess
                }
            },
            isSelected: (item: any, key: string) => {
                const pathName = location.pathname;
                if (pathName === item.href) {
                    return true;
                }

                return false;
            }
        };

        nav.Manage = {
            title: "Manage",
            items: {
                MySubscriptions: {
                    title: "My Subscriptions",
                    component: (props: any) => <Link {...props} to={`/${orgId}/lists`} />,
                    href: `/${orgId}/lists`,
                    icon: (
                        <SvgIcon color="inherit">
                            <MySubscriptionLogo />
                        </SvgIcon>
                    ),
                    regex: []
                },

                PendingMessages: {
                    title: "Pending Messages",
                    component: (props: any) => <Link {...props} to={`/${orgId}/admin/messages/pending`} />,
                    href: `/${orgId}/admin/messages/pending`,
                    icon: (
                        <SvgIcon color="inherit">
                            <path d={mdiCalendarClock} />
                        </SvgIcon>
                    ),
                    regex: [`/${orgId}/admin/messages/pending/.*`],
                    hide: !isSuperUser && !organization?.isOrgOwner && !organization?.hasAccess,
                    excludedPaths: []
                },

                SentMessages: {
                    title: "Sent Messages",
                    component: (props: any) => <Link {...props} to={`/${orgId}/admin/messages/history`} />,
                    href: `/${orgId}/admin/messages/history`,
                    icon: (
                        <SvgIcon color="inherit">
                            <path d={mdiEmailArrowRightOutline} />
                        </SvgIcon>
                    ),
                    regex: [`/${orgId}/admin/messages/history/.*`],
                    hide: !isSuperUser && !organization?.isOrgOwner && !organization?.hasAccess,
                    excludedPaths: []
                },
                Subscribers: {
                    title: "Subscribers",
                    component: (props: any) => <Link {...props} to={`/${orgId}/admin/subscribers`} />,
                    href: `/${orgId}/admin/subscribers`,
                    icon: <PeopleIcon />,
                    regex: [],
                    hide: !isSuperUser && !organization?.isOrgOwner && !organization?.hasAccess,
                    excludedPaths: []
                },
                SubscriptionLists: {
                    title: "Subscription Lists",
                    component: (props: any) => <Link {...props} to={`/${orgId}/admin/lists`} />,
                    href: `/${orgId}/admin/lists`,
                    icon: <ViewListIcon />,
                    regex: [`/${orgId}/admin/lists/.*`],
                    hide: !isSuperUser && !organization?.isOrgOwner && !organization?.hasAccess
                }
            },
            isSelected: (item: any, key: string) => {
                const pathName = location.pathname;
                if (pathName === item.href) {
                    return true;
                }

                if (item.regex && item.regex.length > 0) {
                    const regexp = new RegExp(item.href, "gi");
                    if (regexp.test(pathName)) {
                        return true;
                    }
                }
                return false;
            }
        };

        nav.Admin = {
            title: "Administration",
            hide: !isSuperUser && !organization?.isOrgOwner && !organization?.hasAccess,
            items: {
                Settings: {
                    title: "Settings",
                    component: (props: any) => <Link {...props} to={`/${orgId}/admin/settings`} />,
                    href: `/${orgId}/admin/settings`,
                    hide: !isSuperUser && !organization?.isOrgOwner,
                    icon: <SettingsIcon />
                },
                Permissions: {
                    title: "Permissions",
                    component: (props: any) => <Link {...props} to={`/${orgId}/admin/permissions`} />,
                    href: `/${orgId}/admin/permissions`,
                    hide: !isSuperUser && !organization?.isOrgOwner,
                    icon: (
                        <SvgIcon color="inherit">
                            <path d={mdiAccountKey} />
                        </SvgIcon>
                    )
                },
                Templates: {
                    title: "Templates",
                    component: (props: any) => <Link {...props} to={`/${orgId}/admin/templates`} />,
                    href: `/${orgId}/admin/templates`,
                    hide: !isSuperUser && !organization?.isOrgOwner,
                    icon: <NoteIcon />
                },
                History: {
                    title: "History",
                    component: (props: any) => <Link {...props} to={`/${orgId}/admin/history`} />,
                    href: `/${orgId}/admin/history`,
                    icon: <HistoryIcon />,
                    hide: !isSuperUser && !organization?.isOrgOwner && !organization?.hasAccess
                },
                Tools: {
                    title: "User Activity",
                    icon: <ManageAccountsIcon />,
                    component: (props: any) => <Link {...props} to={`/admin/user/activity`} />,
                    href: `/admin/user/activity`,
                    hide: !isSuperUser
                }
            },
            isSelected: (item: any, key: string) => {
                const pathName = location.pathname;
                if (pathName === item.href) {
                    return true;
                }

                if (item.regex && item.regex.length > 0) {
                    const regexp = new RegExp(item.href, "gi");
                    if (regexp.test(pathName)) {
                        return true;
                    }
                }
                return false;
            }
        };

        return nav;
    };

    const onOrgSelect = (org: CppOrganization) => {
        bottle.container.WindowRouter.assign(`${window.location.origin}/${org.name}/${isCitizenView ? "" : "admin"}`);
    };

    const helpMenuOptions = useMemo(() => {
        const menuItems: MenuItem[] = [
            {
                display: "Help Center",
                action: () =>
                    window.open(
                        "https://www.platform.civicplus.help/hc/en-us/categories/360004366394-CivicPlus-Notifications"
                    )
            },
            {
                display: "System Status",
                action: () =>
                    window.open(
                        "https://www.status.civicplus.help/hc/en-us/community/topics/360000112533-CivicPlus-Platform"
                    )
            },
            {
                display: "Chat with Support",
                action: () => {
                    Zendesk.show();
                }
            }
        ];
        return {
            menuItems,
            show: !isCitizenView
        };
    }, [isCitizenView]);

    return (
        <Page
            id="civic-notify-page-wrapper"
            title="Notifications • CivicPlus"
            currentOrg={pageOrganization}
            onOrgSelect={onOrgSelect}
            orgServiceSdk={orgServiceSdk}
            OrgSwitcherProps={{
                showGeolocation: false,
                showRecentOrganizations: true,
                hide: false,
                superUserRole: undefined
            }}
            navigationSections={buildNavigation()}
            helpMenuOptions={helpMenuOptions}
            type={isCitizenView ? "external" : "internal"}
            accountMenuOptions={{
                includeDefault: false,
                show: isAuthenticated,
                signoutUrl: `${signoutUrl}`,
                menuItems: [
                    {
                        display: "Reset Password",
                        action: () => window.open(userManager.getResetPasswordUrl(), "_blank")
                    },
                    {
                        display: "Settings",
                        action: () => window.open(userManager.getSettingsUrl(), "_blank")
                    },
                    {
                        display: "Sign Out",
                        action: () => userManager.signOut()
                    }
                ]
            }}
            productNavSections={[
                {
                    title: "Notifications"
                }
            ]}
        >
            {loading ? <></> : <Pendo />}
            {loading ? <Loader verticallyCenter={true} /> : children}
        </Page>
    );
};

export default PageWrapperInternal;

export const PageWrapper = withRouter(PageWrapperInternal);
