import { Alert } from "@civicplus/preamble-ui/lib/Utilities/Alert";
import { AxiosRequestConfig } from "axios";
import buildQuery from "odata-query";
import { ODataQuery } from "../entities/ODataQuery";
import { IdentityUser } from "../entities/User";
import { bottle } from "../provider/Bottle";
import { IHttpProvider } from "../provider/http/base/HttpProvider";
import { UserManager } from "./UserManager";
import { WindowRouter } from "../shell/WindowRouter";

export class ApiService {
    private baseUrl = `${window.location.origin}/api`;
    private identityUrl = `${window.location.origin}/identity-server`;
    private _userCached: IdentityUser | null = null;
    private _orgId = "";

    public async defaultHeaders() {
        const headers: any = {};
        headers["Access-Control-Allow-Origin"] = "*";

        const user = await this.getUser();
        if (user) {
            headers.Authorization = `${user.token_type} ${user.access_token}`;
        }

        return headers;
    }

    constructor(
        private readonly _http: IHttpProvider,
        private readonly _router: WindowRouter
    ) {}

    public async getUser(): Promise<IdentityUser | null> {
        if (this._userCached) {
            return this._userCached;
        }

        const manager: UserManager = bottle.container.UserManager;
        if (!manager.isInitialized()) {
            return null;
        }

        const user = await manager.getUserAsync();
        if (!user || user.expired) {
            return null;
        }

        this._userCached = {
            token_type: user.token_type,
            id_token: user.id_token,
            scope: user.scope,
            profile: { email: (user.profile || {}).email },
            access_token: user.access_token,
            expires_at: user.expires_at
        };

        return this._userCached;
    }

    public set orgId(orgId: string) {
        this._orgId = orgId;
    }

    public get orgId(): string {
        return this._orgId;
    }

    public get baseOrgRoute(): string {
        return `${this.baseUrl}/${this._orgId}`;
    }

    public get = async (url: string, config: AxiosRequestConfig = {}): Promise<any> =>
        await this.makeRequest(`${this.baseOrgRoute}/${url}`, "GET", null, config);

    public getNoOrg = async (url: string, config: AxiosRequestConfig = {}): Promise<any> =>
        await this.makeRequest(`${this.baseUrl}/${url}`, "GET", null, config);

    public getOdata = async (url: string, odata?: ODataQuery, config: AxiosRequestConfig = {}): Promise<any> => {
        const query =
            (odata &&
                buildQuery({
                    ...odata,
                    count: true,
                    orderBy: `${odata.orderBy} ${odata.orderByDirection}`
                })) ||
            "";

        return await this.makeRequest(
            `${this.baseOrgRoute}/${url}${this.hasQueryParamsAlready(url) ? `&${query.slice(1)}` : query}`,
            "GET",
            null,
            config
        );
    };

    public post = async (url: string, body: any, config: AxiosRequestConfig = {}): Promise<any> =>
        await this.makeRequest(`${this.baseOrgRoute}/${url}`, "POST", body, config);

    public postNoOrg = async (url: string, body: any, config: AxiosRequestConfig = {}): Promise<any> =>
        await this.makeRequest(`${this.baseUrl}/${url}`, "POST", body, config);

    public put = async (url: string, body: any, config: AxiosRequestConfig = {}): Promise<any> =>
        await this.makeRequest(`${this.baseOrgRoute}/${url}`, "PUT", body, config);

    public delete = async (url: string, config: AxiosRequestConfig = {}): Promise<any> =>
        await this.makeRequest(`${this.baseOrgRoute}/${url}`, "DELETE", null, config);

    public getIdentity = async (url: string, config: AxiosRequestConfig = {}): Promise<any> =>
        await this.makeRequest(`${this.identityUrl}/${url}`, "GET", null, config);

    public clearCache = async (url: string, config: AxiosRequestConfig = {}): Promise<any> =>
        await this.makeRequest(`${this.baseUrl}/cache/flush`, "GET", null, config);

    public getFile = async (
        url: string,
        fileName: string,
        odata?: ODataQuery,
        config: AxiosRequestConfig = {}
    ): Promise<any> => {
        config.headers = { ...config.headers, ...(await this.defaultHeaders()) };
        config.responseType = "text";

        const query =
            (odata &&
                buildQuery({
                    ...odata,
                    count: true,
                    orderBy: `${odata.orderBy} ${odata.orderByDirection}`
                })) ||
            "";

        const response = await this.makeRequest(`${this.baseOrgRoute}/${url}${query}`, "GET", null, config);

        const blob = new Blob([response], { type: "text/plain;charset=utf-8" });

        const isEdge = navigator.userAgent.match(/Edge/g);

        if (isEdge) {
            // In FF link must be added to DOM to be clicked
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click(); // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
            document.body.removeChild(link);
        } else {
            const a = document.createElement("a");
            a.href = URL.createObjectURL(blob);

            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    };

    private makeRequest = async (
        url: string,
        method: string,
        body: any,
        config: AxiosRequestConfig | any
    ): Promise<any> => {
        config.headers = { ...config.headers, ...(await this.defaultHeaders()) };

        try {
            const res = await this._http.request({ url, method, data: body, ...config });
            return !res ? null : res.data;
        } catch (e: any) {
            const response = e.response;

            if (response && response.status === 403) {
                this._router.assign("/403");
            }
            if (response && response.status === 404) {
                return response.data.error || response.data.message;
            }

            if (response && response.data) {
                const message = response.data.error || response.data.message;
                if (message) {
                    !config.hideAlertOnError && Alert.showError(message);
                    // eslint-disable-next-line
                    throw { error: message };
                }
            }

            const message = e.message || "Something went wrong.";

            if (!config.hideAlertOnError) {
                Alert.showError(message);
            }

            // eslint-disable-next-line
            throw { error: message };
        }
    };

    private hasQueryParamsAlready(url: string) {
        return url.indexOf("?") !== -1;
    }
}
